<template>
  <div></div>
</template>

<script>
export default {
  name: "PipeNews",

  mounted() {
    console.log("--------- Pipeline Step News ----------");
    this.$store.dispatch("news/fetchNews").finally(() => {
      this.$store.commit("popups/setNextPipelineQueueStep");
    });
  },
};
</script>

<style scoped lang="scss"></style>
